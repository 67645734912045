import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/ubuntu/flomesh.io-main/src/layouts/Fixed/DocMdx.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "介绍"
    }}>{`介绍`}</h2>
    <p>{`Pipy Repo 的概念引入，很大程度上借鉴了 git repo 和 git ops 的管理思路。这些配置、数据、脚本（我们称为 “代码库”），在 Repo 上以树形结构存储，大家可以认为和文件系统是一样的；不过在实现上，所有的这些资源都是以 Key-Value 方式在内存中存储，后续会逐渐的支持各种持久化存储，比如文件系统、数据库、git repo、key-value store。`}</p>
    <p>{`Pipy Repo 提供了与`}<a parentName="p" {...{
        "href": "/operating/admin-gui/"
      }}>{`开发控制台`}</a>{`类似的图形用户界面，方便代码库的开发和维护。同时图形用户界面中内置了文档以及包含内置函数、类型的 API（陆续添加中）。可以在`}<a parentName="p" {...{
        "href": "/getting-started/build-install"
      }}>{`构建`}</a>{`的时候指定开启图形用户界面。`}</p>
    <p>{`另外也可以在构建时选择将源码中 `}<a parentName="p" {...{
        "href": "https://github.com/flomesh-io/pipy/tree/main/tutorial"
      }}>{`tutorial 目录`}</a>{` 的内容以代码库的方式添加到 Repo 中，详细方式见 Pipy 的`}<a parentName="p" {...{
        "href": "/getting-started/build-install"
      }}>{`构建文档`}</a>{`。`}</p>
    <h3 {...{
      "id": "原理"
    }}>{`原理`}</h3>
    <p>{`Proxy 节点在启动的时候，指定一个 HTTP 的地址，比如 `}<inlineCode parentName="p">{`http://repo.flomesh.cn/myrepo/service-x-proxy/`}</inlineCode>{` ，此时 proxy 节点会定时通过 REST 去同步这个目录下的资源。在指定路径的时候，可以指定一个子目录，则 proxy 节点只会同步这个子目录的内容。这里借鉴了 git ops 的思路：资源是全局统一存储的，但是执行时候只获取和自己相关的资源。`}</p>
    <p>{`当 Proxy 节点连接到 Pipy Repo 时候，Pipy Repo 会记录这个节点的状态，以后每次 proxy 节点查询资源是否有变化的时候，Pipy Repo 都会记录为 “此 proxy 节点是 active 的”。这很像服务实例注册到注册中心，并定时发送心跳。Proxy 节点和 repo 节点之间使用 HTTP 通讯，因此不仅仅适用于局域网环境，也可以在广域网环境使用。`}</p>
    <p>{`Pipy Repo 暴露了一系列的 `}<a parentName="p" {...{
        "href": "/operating/repo/3-api"
      }}>{`REST API`}</a>{`，用于增删改查 repo 中的资源。在服务网格的场景，可以用作控制平面的 adaptor，通过 REST API 与各种管理平台对接。`}</p>
    <h3 {...{
      "id": "代码库状态"
    }}>{`代码库状态`}</h3>
    <p>{`代码库有状态以及版本的概念，见下图。修改但未发布的代码库，对 Proxy 节点是不可见的。`}</p>
    <p><img parentName="p" {...{
        "src": "/img/docs/repo/codebase-states.png",
        "alt": null
      }}></img></p>
    <h3 {...{
      "id": "代码库继承"
    }}>{`代码库继承`}</h3>
    <p>{`代码库间可以是独立的，也可以建立类似继承的关系。但是在 Pipy Repo 中称作：Base（基础）和 Derivative（衍生）。`}</p>
    <p>{`衍生版在基础版的基础上，可以修改基础版的脚本和配置，也可以增加新的脚本的配置。`}</p>
    <p>{`这种特性体现了代码复用的思想，通过编写高内聚、配置分离、模块化的脚本，配合代码库的继承实现能力的复用。`}</p>
    <p><img parentName="p" {...{
        "src": "/img/docs/repo/codebase-inheritance.png",
        "alt": null
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      